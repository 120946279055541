import Head from 'next/head'
import Header from '../components/Header';
import LargeCTA from '../components/LargeCTA';
import CTA from '../components/CTA';
import Footer from '../components/Footer';
import { client } from '../lib/apollo';
import moment from "moment";
import { Swiper, SwiperSlide } from 'swiper/react';
import {Pagination } from 'swiper';
import Layout from "../components/Layout";
import Script from 'next/script'
import parse from "html-react-parser";
import Image from 'next/image';

import 'swiper/css/bundle';

import { useRouter } from "next/router";

import { GET_HOME, GET_PAGE_OPTIONS, GET_HOME_BLOG_POSTS, SEARCH, GET_BLOG_FEATURED_IMAGE } from '../queries/queries';

import { useState, useEffect} from "react";

import FsLightbox from "fslightbox-react";

export default function Home({ page }) {
  const [cta, setCTA] = useState(0);
  const [largeCTA, setLargeCTA] = useState('');
  const [homePage, setHomePage] = useState({});
  const [blogPosts, setBlogPosts] = useState('');
  const [searchData, setSearchData] = useState('');
  const [videoToggler, setVideoToggler] = useState(false);
  const [seoOptions, setSEOOptions] = useState('');
  const [pathData, setPathData] = useState('');
  const [defaultImage, setDefaultImage] = useState('');

  const fullHead = parse(page.seo.fullHead);

  const router = useRouter();
  const query = router.query;

  useEffect(()=>{
    window.semaphore=window.semaphore||[],window.ketch=function(){window.semaphore.push(arguments)};var e=new URLSearchParams(document.location.search),o=e.has("property")?e.get("property"):"website_smart_tag",n=document.createElement("script");n.type="text/javascript",n.src="https://global.ketchcdn.com/web/v2/config/the_signatry/".concat(o,"/boot.js"),n.defer=n.async=!0,document.getElementsByTagName("head")[0].appendChild(n)
  
      async function fetchPageOptions() {
        if(query.s){
          const searchResults = await client.query({
              query: SEARCH,
              variables: { search: query.s }
          });

          const searchResultsItems = searchResults.data?.searchWP?.edges;

          setSearchData(searchResultsItems);
        }

        const blogPostList = await client.query({
          query: GET_HOME_BLOG_POSTS
        });

        const blogPostItems = blogPostList.data?.posts?.edges;
        const pageOptionList = await client.query({
            query: GET_PAGE_OPTIONS,
            variables: { slug: page.uri }
        });
    
        const pageOptions = pageOptionList?.data?.pageBy?.pageOptions; 
        const largeCTAItem = pageOptions?.largeCta ? pageOptions.largeCta[0].largeCta : null;
        const ctaItem = pageOptions?.callToAction ? pageOptions.callToAction[0].cta : null;

        setCTA(ctaItem);
        setLargeCTA(largeCTAItem);
        setHomePage(page.home);
        setBlogPosts(blogPostItems);

        const seoData = page?.seo;
        setSEOOptions(seoData);

        var canonical = page?.seo?.canonical;
        var path = '';

        if(canonical){
          path = canonical.toString().replace('https://', '').replace('http://', '');
          path = path.substring(path.toString().indexOf("/"), path.length);
        }

        setPathData(window.location.protocol + "//" + window.location.host + path);
      }

      async function fetchDefaultOptions() {
        const blogDefaultImage = await client.query({
            query: GET_BLOG_FEATURED_IMAGE
        });

        const defaultImage = blogDefaultImage?.data?.siteOptions?.siteOptions?.defaultImage;

        setDefaultImage(defaultImage);
      }

      fetchPageOptions();
      fetchDefaultOptions();
    }, []);

  return (
    <Layout>
      <div>
        <Head>
          {fullHead}
          <meta name="MobileOptimized" content="width" />
          <meta name="HandheldFriendly" content="True"/>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0" />
          <title>The Signatry</title>
          <link rel="icon" href="/assets/favicon/favicon.ico"></link>
          <Script src="https://cdn.jsdelivr.net/npm/swiper@9/swiper-element-bundle.min.js" />
          <Script id="google-tag-manager" strategy="afterInteractive">
              {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-5QQ4SLR');
              `}
          </Script>
        </Head>
        <noscript dangerouslySetInnerHTML={{
            __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5QQ4SLR" height="0" width="0" style="display:none;visibility:hidden"></iframe>`
        }}></noscript>
        <Header></Header>
        <main>
          <div id="content" role="main">
            {
              searchData !== '' && (
                  <div id="search-results-container" className='container'>
                    {
                      searchData.length > 0 && (
                        searchData.map( (searchItem, index) => {
                          return (
                            <div className='search-result-item'>
                              <a href={`${searchItem.node.uri}`}>
                                <h4>{searchItem.node.title}</h4>
                                {
                                  searchItem.node.excerpt && (
                                    <div dangerouslySetInnerHTML={{__html: searchItem.node.excerpt}}></div>
                                  )
                                }
                              </a>
                            </div>
                          )
                        })
                      )
                    }
                  </div>
              )
            }
            {
              searchData === '' && (
                <>
            <div id="hero-container" className='container-fluid'>
              <div id="hero-content" className='container'>
                <div>
                  <div>
                    {
                      homePage.heroTitle && (
                        <div dangerouslySetInnerHTML={{__html: homePage.heroTitle}}></div>
                      )
                    }
                    {
                      homePage.heroSubtitle && (
                        <h2 className='subtitle'>{homePage.heroSubtitle}</h2>
                      )
                    }
                    {
                      homePage.heroButtons?.length > 0 && (
                        <div id="hero-buttons">
                          {
                            homePage.heroButtons.map( (item, index) => {
                            return (
                                <div>
                                  <div dangerouslySetInnerHTML={{__html: item.button}}></div>
                                </div>
                            )
                            })
                          }
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
              <div id="hero-image" style={{  
              backgroundImage: homePage.heroImage ? "url(" + homePage.heroImage.sourceUrl + ")" : ""
            }}></div>
            </div>
            <div id="widgets-container" className='container-fluid'>
              <div className="container">
                <div>
                    {
                        homePage.widgets?.length > 0 && (
                            homePage.widgets.map( ( imageWidget, index ) => {
                                return (
                                  <div className="widget" key={index}>
                                      <div className='image-wrapper'>
                                        <Image
                                          src={imageWidget?.image?.sourceUrl}
                                          srcSet={imageWidget.image?.srcSet}
                                          sizes={imageWidget?.image?.sizes}
                                          height={
                                            imageWidget?.image?.mediaDetails?.height
                                          }
                                          quality={100}
                                          priority={true}
                                          width={imageWidget?.image?.mediaDetails?.width}
                                          alt={imageWidget.image.altText}
                                          title={imageWidget.image.title}
                                        />
                                      </div>
                                      <div className="content">
                                          <div>
                                              <div dangerouslySetInnerHTML={{__html: imageWidget.content}}></div>
                                          </div>
                                      </div>
                                  </div>
                                )
                            })
                        )
                    }
                  </div>
              </div>
            </div>
            <div id="home-difference-container" className='container'>
              <div>
                <div>
                  <div className='image-wrapper'>
                    {
                      homePage.differenceImage  && (
                        <Image
                          src={homePage?.differenceImage?.sourceUrl}
                          srcSet={homePage.differenceImage?.srcSet}
                          sizes={homePage?.differenceImage?.sizes}
                          height={
                            homePage?.differenceImage?.mediaDetails?.height
                          }
                          quality={100}
                          width={homePage?.differenceImage?.mediaDetails?.width}
                          alt={homePage.differenceImage.altText}
                          title={homePage.differenceImage.title}
                        />  
                      )
                    }
                    <div>
                      <div>
                        <div>
                          <a href="#" aria-label="View Video" onClick={() => setVideoToggler(!videoToggler)}>
                            <span className='icon-play'></span>
                          </a>
                          <FsLightbox
                            toggler={videoToggler}
                            sources={[
                                  <iframe
                                    src={`${homePage.differenceVideo}?autoplay=1`}
                                    width="1920px"
                                    height="1080px"
                                    frameBorder="0"
                                    allow="autoplay; fullscreen"
                                    allowFullScreen
                                  />
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <div dangerouslySetInnerHTML={{__html: homePage.differenceContent}}></div>
                  </div>
                </div>
              </div>
            </div>
            <div id="home-testimonials-container" className='container-fluid'>
              <div className="container">
                  {
                      homePage.testimonials?.length > 0 && (
                          <Swiper
                            modules={[Pagination]}
                  loop
                  speed={1000}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                >
                {
                              homePage.testimonials.map( ( item, index ) => {
                                  return (
                                      <SwiperSlide key={index}>
                                          <div>
                                            <div className="container large-container">
                                              <div>
                                                <div dangerouslySetInnerHTML={{__html: item.testimonial}}></div>
                                              </div>
                                            </div>
                                          </div>
                                      </SwiperSlide>
                                  )
                              })
                          }
                </Swiper>
                      )
                  }
              </div>
            </div>
            <div id="home-giving-container" className='container'>
              <div>
                <div>
                  <div dangerouslySetInnerHTML={{__html: homePage.givingContent}}></div>
                </div>
                <div>
                  <div dangerouslySetInnerHTML={{__html: homePage.givingWidgetContent}}></div>
                </div>
              </div>
            </div>
            <div id="home-blog-container" className='container-fluid'>
                <div className='container'>
                  <div>
                    <div dangerouslySetInnerHTML={{__html: homePage.blogTitle}}></div>
                  </div>
                  {
                    blogPosts.length > 0 && (
                      <div>
                        <div>
                            <div>
                              <a className='featured-image-link' aria-label={`${blogPosts[0].node.title} Link`} href={`${blogPosts[0].node.uri}`}>
                                {
                                  blogPosts[0]?.node?.featuredImage && (
                                    <Image
                                      src={blogPosts[0].node.featuredImage.node.sourceUrl}
                                      srcSet={blogPosts[0].node.featuredImage.node.srcSet}
                                      sizes={blogPosts[0].node.featuredImage.node.sizes}
                                      height={
                                        blogPosts[0].node.featuredImage.node.mediaDetails?.height
                                      }
                                      quality={100}
                                      width={blogPosts[0].node.featuredImage.node.mediaDetails?.width}
                                      alt={blogPosts[0].node.featuredImage.node.altText}
                                      title={blogPosts[0].node.featuredImage.node.title}
                                    />
                                  )
                                }
                                {
                                  !blogPosts[0]?.node?.featuredImage && (
                                    <Image
                                        src={defaultImage.sourceUrl}
                                        srcSet={defaultImage.srcSet}
                                        sizes={defaultImage.sizes}
                                        height={
                                          defaultImage.mediaDetails?.height
                                        }
                                        quality={100}
                                        width={defaultImage.mediaDetails?.width}
                                        alt={defaultImage.altText}
                                        title={defaultImage.title}
                                      />
                                  )
                                }
                              </a>
                              <a className='title-link' href={`${blogPosts[0].node.uri}`}>
                                <h3>{blogPosts[0].node.title}</h3>
                              </a>
                              <div className="blog-list-details">
                                <p>{moment(blogPosts[0].node.date).format('MMMM D, YYYY')}</p>
                                  {
                                    blogPosts[0].node.categories.edges.length > 0 && (
                                      <div>
                                        <span>|</span>
                                        {
                                          blogPosts[0].node.categories.edges.map( (cat, index) => {
                                            return (
                                              <a href={`${cat.node.uri}`}>
                                                {cat.node.name}<span className="sep">,&nbsp;</span>
                                              </a>
                                            )
                                          })
                                        }
                                      </div>
                                    )
                                  }
                                </div>
                                {
                                    blogPosts[0].node.blogPost?.excerpt && (
                                        <div className="excerpt" dangerouslySetInnerHTML={{__html: blogPosts[0].node.blogPost.excerpt}}></div>
                                    )
                                }
                                {
                                    !blogPosts[0].node.blogPost?.excerpt && (
                                        <p className="excerpt" dangerouslySetInnerHTML={{__html: blogPosts[0].node.blogPost.excerpt}}></p>
                                    )
                                }
                            </div>
                        </div>
                        <div>
                          <div className="blog-list-item">
                              <div>
                                  <a className='featured-image-link' aria-label={`${blogPosts[1].node.title} Link`} href={`${blogPosts[1].node.uri}`}>
                                    {
                                      blogPosts[1]?.node?.featuredImage && (
                                        <Image
                                          src={blogPosts[1].node.featuredImage.node.sourceUrl}
                                          srcSet={blogPosts[1].node.featuredImage.node.srcSet}
                                          sizes={blogPosts[1].node.featuredImage.node.sizes}
                                          height={
                                            blogPosts[1].node.featuredImage.node.mediaDetails?.height
                                          }
                                          quality={100}
                                          width={blogPosts[1].node.featuredImage.node.mediaDetails?.width}
                                          alt={blogPosts[1].node.featuredImage.node.altText}
                                          title={blogPosts[1].node.featuredImage.node.title}
                                        />
                                      )
                                    }
                                    {
                                      !blogPosts[1]?.node?.featuredImage && (
                                        <Image
                                          src={defaultImage.sourceUrl}
                                          srcSet={defaultImage.srcSet}
                                          sizes={defaultImage.sizes}
                                          height={
                                            defaultImage.mediaDetails?.height
                                          }
                                          quality={100}
                                          width={defaultImage.mediaDetails?.width}
                                          alt={defaultImage.altText}
                                          title={defaultImage.title}
                                        />
                                      )
                                    }
                                  </a>
                              </div>
                              <div>
                                  <a className='title-link' href={`${blogPosts[1].node.uri}`}>
                                    <h3>{blogPosts[1].node.title}</h3>
                                  </a>
                                  <div className="blog-list-details">
                                  <p>{moment(blogPosts[1].node.date).format('MMMM D, YYYY')}</p>
                                  {
                                    blogPosts[1].node.categories.edges.length > 0 && (
                                      <div>
                                        <span>|</span>
                                        {
                                          blogPosts[1].node.categories.edges.map( (cat, index) => {
                                            return (
                                              <a href={`${cat.node.uri}`}>
                                                {cat.node.name}<span className="sep">,&nbsp;</span>
                                              </a>
                                            )
                                          })
                                        }
                                      </div>
                                    )
                                  }
                                  </div>
                              </div>
                          </div>
                          <div className="blog-list-item">
                              <div>
                                  <a className='featured-image-link' aria-label={`${blogPosts[2].node.title} Link`} href={`${blogPosts[2].node.uri}`}>
                                    {
                                      blogPosts[2]?.node?.featuredImage && (
                                        <Image
                                          src={blogPosts[2].node.featuredImage.node.sourceUrl}
                                          srcSet={blogPosts[2].node.featuredImage.node.srcSet}
                                          sizes={blogPosts[2].node.featuredImage.node.sizes}
                                          height={
                                            blogPosts[2].node.featuredImage.node.mediaDetails?.height
                                          }
                                          quality={100}
                                          width={blogPosts[2].node.featuredImage.node.mediaDetails?.width}
                                          alt={blogPosts[2].node.featuredImage.node.altText}
                                          title={blogPosts[2].node.featuredImage.node.title}
                                        />
                                      )
                                    }
                                    {
                                      !blogPosts[2]?.node?.featuredImage && (
                                        <Image
                                          src={defaultImage.sourceUrl}
                                          srcSet={defaultImage.srcSet}
                                          sizes={defaultImage.sizes}
                                          height={
                                            defaultImage.mediaDetails?.height
                                          }
                                          quality={100}
                                          width={defaultImage.mediaDetails?.width}
                                          alt={defaultImage.altText}
                                          title={defaultImage.title}
                                        />
                                      )
                                    }
                                  </a>
                              </div>
                              <div>
                                  <a className='title-link' href={`${blogPosts[2].node.uri}`}>
                                    <h3>{blogPosts[2].node.title}</h3>
                                  </a>
                                  <div className="blog-list-details">
                                  <p>{moment(blogPosts[2].node.date).format('MMMM D, YYYY')}</p>
                                  {
                                    blogPosts[2].node.categories.edges.length > 0 && (
                                      <div>
                                        <span>|</span>
                                        {
                                          blogPosts[2].node.categories.edges.map( (cat, index) => {
                                            return (
                                              <a href={`${cat.node.uri}`}>
                                                {cat.node.name}<span className="sep">,&nbsp;</span>
                                              </a>
                                            )
                                          })
                                        }
                                      </div>
                                    )
                                  }
                                  </div>
                              </div>
                          </div>
                        </div>
                      </div>
                    )
                  }
                  <div id="home-blog-button-container" className='container'>
                    <a href="/blog/" aria-label='The Signatry Blog' className='button green'>
                      <span className='icon-arrow'></span>
                      All Posts
                    </a>
                  </div>
                </div>
            </div>
            </>
              )}
          <LargeCTA
            largeCTA={largeCTA}
          ></LargeCTA>
          <CTA
            cta={cta}
          ></CTA>
          </div>
        </main>
        <Footer></Footer>
      </div>
    </Layout>
  )
}

export async function getStaticProps(params){
  const response = await client.query({
    query: GET_HOME,
    variables: { slug: '/' }
  });

  const page = response?.data?.pageBy; 

  return {
    props: {
      page: page
    }
  }
}